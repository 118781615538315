/*
 * @Author: Gang Jiang 
 * @Date: 2025-02-19 22:11:06 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2025-02-20 10:06:30
 */
 <template>
  <div class="home">
    <h1>For Seller</h1>
    <a href="/help/seller-general-account-setup"
      >Seller General Account Setup</a
    >
    <a href="/help/activate-brand(s)">Activate Brand(s)</a>
    <a href="/help/activate-product(s)">Activate Product(s)</a>
    <a href="/help/manage-multiple-sellers">Manage Multiple Sellers</a>
  </div>
</template>